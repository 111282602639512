import ConsultantLayout from 'components/ConsultantLayout';
import Leave from 'pages/consultant/Leave';
import Myprofile from 'pages/consultant/Myprofile';
import PaySheet from 'pages/consultant/PaySheet';
// import PaySheet from 'pages/consultant/PaySheet';
import Timesheet from 'pages/consultant/Timesheet';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ConsultantRoutes } from '../index';

const ConsultantRoute: React.FC = (): React.ReactElement => {
  const { PAYSHEET, TIMESHEET, PROFILE, LEAVE } = ConsultantRoutes;
  // const { TIMESHEET, PROFILE, LEAVE } = ConsultantRoutes;

  return (
    <Routes>
      <Route path="/" element={<Navigate to={TIMESHEET} />} />
      <Route
        path={TIMESHEET}
        element={
          <ConsultantLayout
            routes={{
              path: TIMESHEET,
              show: false,
              title: 'timeSheet',
            }}
            from={'timeSheet'}
            showHeader={false}>
            <Timesheet />
          </ConsultantLayout>
        }
      />
      {/* Hide Paysheet Tab for SmartWorks Employees [PUAT-340] */}
      <Route
        path={PAYSHEET}
        element={
          <ConsultantLayout
            routes={{
              path: PAYSHEET,
              show: false,
              title: 'paySheet',
            }}
            from={'paySheet'}
            showHeader={false}>
            <PaySheet />
          </ConsultantLayout>
        }
      />
      <Route
        path={LEAVE}
        element={
          <ConsultantLayout
            routes={{
              path: LEAVE,
              show: false,
              title: 'leave',
            }}
            from={'leave'}
            showHeader={false}>
            <Leave />
          </ConsultantLayout>
        }
      />
      <Route
        path={PROFILE}
        element={
          <ConsultantLayout
            routes={{
              path: PROFILE,
              show: false,
              title: 'profile',
            }}
            from={'profile'}
            showHeader={false}>
            <Myprofile />
          </ConsultantLayout>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default ConsultantRoute;
