import React from 'react';
import { useAppSelector } from 'store/hooks';

import PaySheetData from '../../common/PaySheet';

const PaySheet: React.FC = () => {
  const { userInfo }: any = useAppSelector((store: any) => store.userLogin);
  return <PaySheetData consultantId={userInfo?.id} />;
};

export default PaySheet;
