/* eslint-disable react/react-in-jsx-scope */
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Grid,
  Typography,
} from '@mui/material';
import { pink } from '@mui/material/colors';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import HeaderNotification from 'assets/images/notification-black.svg';
import profileimg from 'assets/images/profileimg.png';
import HeaderLogo from 'assets/images/RiseIt-black.svg';
import { ConsultantRoutes } from 'config';
import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  deleteData,
  getAllListData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT, PAYSHEET, TIMESHEET } from 'utils/Constant';

import CustomButton from './CustomButton/customButton';

type headConfigsProps = {
  routes: any;
  showTools?: boolean;
  showHeader?: boolean;
  addEditRoute?: any;
  children: any;
  from?: string;
};

const ConsultantLayout = (props: headConfigsProps) => {
  const [imageId, setImageId] = React.useState<string>('');
  const [paymentId, setPaymentId] = React.useState<number>(1);
  const [notificationList, setNotificationList] = React.useState<any>([]);
  const [notificationCount, setNotificationCount] = React.useState<number>();
  const [payrollData, setPayrollData] = React.useState<any>({});
  const [payPeriodData, setPayPeriodData] = React.useState<any>([]);
  const [currentPayPeriodData, setCurrentPayPeriodData] = React.useState<any>(
    [],
  );
  const [partialAmtarray, setPartialAmtarray] = React.useState<any>([]);
  const [deductFlatTax, setDeductFlatTax] = React.useState<boolean>(false);

  const getPayPeriodDataData = () => {
    getAllListData(
      MASTER_ENDPOINT.Payperiods + '?year=' + moment().format('yyyy'),
    )
      .then((resp: any) => {
        const dateArray = [];
        if (resp?.data) {
          if (resp?.data?.[0]) {
            if (resp?.data?.[0]?.payPeriodQuarters) {
              resp?.data?.[0]?.payPeriodQuarters.map((item: any) => {
                if (item?.payPeriods) {
                  item?.payPeriods.map((item1: any) => {
                    dateArray.push(item1);
                  });
                }
              });
            }
          }
        }
        if (dateArray.length) {
          const afterPayPeriods = dateArray.filter((date: any) =>
            moment(date.payDate).isAfter(),
          );
          // const afterPayPeriods = dateArray.filter(
          //   (date: any) =>
          //     moment(date.endDate).isAfter(moment().format('YYYY-MM-DD')) &&
          //     !moment(moment().format('YYYY-MM-DD')).isBetween(
          //       moment(date.startDate),
          //       moment(date.endDate),
          //       undefined,
          //       '[]',
          //     ),
          // );
          const currentPayPeriods = dateArray.filter((date: any) =>
            moment(moment().format('YYYY-MM-DD')).isBetween(
              moment(date.startDate),
              moment(date.endDate),
              undefined,
              '[]',
            ),
          );
          // console.log(currentPayPeriods);
          setCurrentPayPeriodData(currentPayPeriods);
          const filterPayPeriods = afterPayPeriods.slice(0, 3);
          const dateAObject = filterPayPeriods.sort(function (a, b) {
            const c: any = new Date(a.startDate);
            const d: any = new Date(b.startDate);
            return d - c;
          });
          // console.log(dateAObject);
          setPayPeriodData(dateAObject);
        }
      })
      .catch(() => {});
  };

  const { userInfo }: any = useAppSelector((store: any) => store.userLogin);

  React.useEffect(() => {
    setImageId(userInfo?.imageId);
  }, [userInfo?.imageId]);

  React.useEffect(() => {
    getNotification();
    getPayRollDetails(userInfo?.id);
    getPayPeriodDataData();
  }, [userInfo?.id]);

  const getPayRollDetails = (userId: string) => {
    const url: any =
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${PAYSHEET.Payroll}?userId=${userId}`;
    getAllListData(url)
      .then((resp: any) => {
        setPayrollData(resp?.data);
      })
      .catch(() => {});
  };

  const getNotification = () => {
    getAllListData(TIMESHEET.Notification).then((resp: any) => {
      setNotificationList(resp?.data);
      setNotificationCount(resp?.total);
    });
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();

  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  const [anchorEl1, setAnchorEl1] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [open1, setOpen1] = React.useState(false);
  const [placement1, setPlacement1] = React.useState<PopperPlacementType>();

  const handleClick1 =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl1(event.currentTarget);
      setOpen1((prev) => placement1 !== newPlacement || !prev);
      setPlacement1(newPlacement);
    };

  const clearNotification = (id: string) => {
    deleteData(id, TIMESHEET.Notification + '/clear').then(() => {
      getNotification();
    });
  };

  // const bufferAmount = () => {
  //   if (payrollData) {
  //     return payrollData.approvedAmount
  //       ? parseFloat(payrollData.approvedAmount).toFixed(2)
  //       : 0 + payrollData.requestedAmount
  //       ? parseFloat(payrollData.requestedAmount).toFixed(2)
  //       : 0 - payrollData?.bufferDue
  //       ? parseFloat(payrollData.requestedAmount).toFixed(2)
  //       : 0;
  //   }
  //   return 0;
  // };

  const [loading, setLoading] = React.useState(false);
  const submitRequest = () => {
    const avAmount: any = payrollData?.availableBuffer
      ? payrollData?.availableBuffer
      : 0;
    if (currentPayPeriodData.length) {
      let postJson = {};
      if (paymentId === 1) {
        postJson = {
          availableBufferAmount: avAmount,
          buffer: [
            {
              payPeriodId: payPeriodData?.[0]?.id,
              requestedBufferAmount: avAmount,
            },
          ],
          currentPayPeriodId: currentPayPeriodData?.[0]?.id,
          deductFlatTax: deductFlatTax,
          type: 'single',
          userId: userInfo?.id,
        };
      } else {
        postJson = {
          availableBufferAmount: avAmount,
          buffer: partialAmtarray,
          currentPayPeriodId: currentPayPeriodData?.[0]?.id,
          deductFlatTax: deductFlatTax,
          type: 'partial',
          userId: userInfo?.id,
        };
        let reqAmt = 0;
        if (partialAmtarray.length) {
          partialAmtarray.map((item) => {
            reqAmt =
              reqAmt + item.requestedBufferAmount
                ? parseFloat(item.requestedBufferAmount)
                : 0;
          });
        }
        if (avAmount < reqAmt) {
          toast.error(
            'Please enter below available buffer amount',
            alertOptions,
          );
          return true;
        }
      }
      setLoading(true);
      addNewData(postJson, PAYSHEET.Payroll + '/request/buffer', {}, true)
        .then(() => {
          setLoading(false);
          setOpen(false);
          getPayRollDetails(userInfo?.id);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      toast.error('Current pay period is not available', alertOptions);
    }
  };

  return (
    <Grid item md={12}>
      <Grid item md={12}>
        <Typography component={'div'}>
          <Typography component={'div'} className={'Consultant-header'}>
            <Grid container>
              <Grid item xs={6}>
                <Box
                  sx={{
                    alignItems: 'center',
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    height: '112px',
                    m: 0,
                    pl: 5,
                  }}>
                  <Typography>
                    <img src={HeaderLogo} />
                  </Typography>
                  <Typography>
                    <ul>
                      <li
                        className={props.from === 'timeSheet' ? 'active' : ''}>
                        <Link
                          to={ConsultantRoutes.TIMESHEET}
                          className={'sidebarA'}>
                          TIMESHEET
                        </Link>
                      </li>
                      {/* Hide Paysheet Tab for SmartWorks Employees [PUAT-340] */}
                      {userInfo?.ultraStaffDbName === 'SmartWorks' && (
                        <li
                          className={props.from === 'paySheet' ? 'active' : ''}>
                          <Link
                            to={ConsultantRoutes.PAYSHEET}
                            className={'sidebarA'}>
                            <a href="#">PAYSHEET</a>
                          </Link>
                        </li>
                      )}
                      <li className={props.from === 'leave' ? 'active' : ''}>
                        <Link
                          to={ConsultantRoutes.LEAVE}
                          className={'sidebarA'}>
                          <a href="#">LEAVE</a>
                        </Link>
                      </li>
                    </ul>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    alignItems: 'center',
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    height: '112px',
                    justifyContent: 'flex-end',
                    m: 0,
                    p: 0,
                  }}>
                  {/* {(userInfo?.jobWcCode ? userInfo.jobWcCode : '').search(
                    'P/I',
                  )} */}
                  <Typography component={'div'} className="notify-bg">
                    {(userInfo?.jobWcCode ? userInfo.jobWcCode : '').search(
                      'P/I',
                    ) === 1 &&
                      userInfo?.ultraStaffDbName === 'SmartWorks' && (
                        // [
                        //   '11403134-b869-4c9c-a33f-8973f34c1254',
                        //   '3cff0ebf-aa65-481c-8508-f39c595f0a64',
                        //   'bb555954-e143-41e2-831e-8ed766055d73',
                        //   '7b167540-3ee3-4ec9-9606-d01f35f26913',
                        //   '5babceea-84ef-47cd-a830-fd1418eb951a',
                        //   'd6204405-da3a-4ffc-bed4-31a9dbf3aa24',
                        //   '048a5ef2-fe6e-45aa-8efe-b061ee38724f',
                        //   '21f981b9-7672-45a6-b371-b896f262b561',
                        //   '7c3d081c-eb88-45e6-8665-07298d8aaa02',
                        //   'fe18535a-fd52-4be0-821d-829286276a09',
                        //   '2be2a384-08d5-4df3-bd05-c3969289c808',
                        //   'de8c3c4c-b898-4c09-852c-54b22d12c120',
                        //   'b4211bd2-90af-4903-8f4f-71426bfa8f35',
                        //   'b8c52c72-a0dd-4a10-813e-8b59b60948f9',
                        //   'a72081b3-18cd-446c-a7fe-faf8673ab576',
                        //   'b3a6a0c9-050f-41e4-b60c-0d1bed407004',
                        //   '1bbbf56a-a873-4fc0-8d46-6ca8acd25a7e',
                        //   '23b6c81b-b939-4426-8823-2a1d991aec1e',
                        //   'c3071ba9-0246-483f-a5c0-58eaeb990c40',
                        //   'd1739999-1423-4421-991b-f356aa1555f7',
                        //   '08ca87fc-1e6f-463b-9eff-dc87a2ccaaf1',
                        //   '324a4485-3820-43e3-9d8f-82f9f0113f3e',
                        //   'cc96d976-c689-493d-8b29-85dd30570dc9',
                        //   '548e700b-7722-47cb-a233-bd57a48317e2',
                        //   '49becdf6-0155-406b-9992-292143f65e0b',
                        //   '4c0e1155-0438-4b0b-8027-501c36d35498',
                        //   '7240d325-80d4-43ac-8896-a756ad2aa604',
                        //   'e590803f-7e49-4b71-a448-6321360f526d',
                        //   '82d67c77-63e4-4beb-b57f-f79d33647ff8',
                        //   'bab5d8d6-4118-46b1-8089-d66ec195e3c3',
                        //   '5a49024e-cc94-46b0-9cd7-a39d7cf15453',
                        //   '8330c941-decd-4137-9950-46fce5535e03',
                        //   '39820012-59da-4511-9472-88a5ea15b3f0',
                        //   '3409f546-0ee2-40c8-bdba-1da2598ee646',
                        //   '253ee087-9377-4a8b-8d21-8f091f5cb35c',
                        //   'b378b53a-f994-4a38-803b-5f50545bef95',
                        //   '27c4388d-0d7d-4280-b0b5-bc521b35f6f6',
                        //   '8de7aeb8-99d9-493e-b6fc-116c56efa8ce',
                        //   '21f7d4a8-64f3-4559-b4e8-47005dbff61b',
                        //   '5b3c4c67-bff3-4cb7-8c0c-56ed78e0f752',
                        //   // removed id
                        //   // 'e8c398ca-ab57-440e-b963-48696a294bd4',
                        // ].includes(userInfo?.id))
                        <Typography className="request-payment">
                          <Typography variant="h6">Available Buffer</Typography>
                          <Typography variant="h5">
                            $
                            {payrollData?.availableBuffer
                              ? payrollData?.availableBuffer
                              : 0}
                          </Typography>
                          <Button
                            variant="outlined"
                            onClick={handleClick('bottom-end')}>
                            Request Payment
                          </Button>
                          <Box>
                            {open && (
                              <ClickAwayListener
                                onClickAway={() => setOpen(false)}>
                                <Popper
                                  open={open}
                                  anchorEl={anchorEl}
                                  placement={placement}
                                  transition>
                                  {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                      <Paper className="model-widget">
                                        <Typography
                                          sx={{ width: '486px' }}
                                          className="request-payment-alert">
                                          <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2">
                                            Request Payment
                                          </Typography>
                                          <Typography
                                            component={'div'}
                                            className="total-payment">
                                            <Typography>
                                              Total Available
                                            </Typography>
                                            <Typography>
                                              <strong>
                                                $
                                                {payrollData?.availableBuffer
                                                  ? payrollData?.availableBuffer
                                                  : 0}
                                              </strong>
                                            </Typography>
                                          </Typography>
                                          {payPeriodData.length ? (
                                            <>
                                              <Typography
                                                component={'div'}
                                                className="total-payment-option">
                                                <Typography>
                                                  <FormControl>
                                                    <RadioGroup
                                                      row
                                                      aria-labelledby="demo-form-control-label-placement"
                                                      name="position"
                                                      defaultValue="top">
                                                      <FormControlLabel
                                                        value="end"
                                                        control={
                                                          <Radio
                                                            sx={{
                                                              '&.Mui-checked': {
                                                                color:
                                                                  pink[600],
                                                              },
                                                              color: pink[800],
                                                            }}
                                                            checked={
                                                              paymentId === 1
                                                                ? true
                                                                : false
                                                            }
                                                          />
                                                        }
                                                        label="Request Full Payment"
                                                        onClick={() =>
                                                          setPaymentId(1)
                                                        }
                                                      />
                                                    </RadioGroup>
                                                  </FormControl>
                                                </Typography>
                                                {paymentId === 1 && (
                                                  <Typography>
                                                    <TextField
                                                      id="outlined-basic"
                                                      variant="outlined"
                                                      value={
                                                        payrollData?.availableBuffer
                                                          ? payrollData?.availableBuffer
                                                          : 0
                                                      }
                                                      disabled
                                                    />
                                                  </Typography>
                                                )}
                                              </Typography>
                                              <Typography
                                                component={'div'}
                                                className="total-payment-option">
                                                <Typography>
                                                  <FormControl>
                                                    <RadioGroup
                                                      row
                                                      aria-labelledby="demo-form-control-label-placement"
                                                      name="position"
                                                      defaultValue="top">
                                                      <FormControlLabel
                                                        value="end"
                                                        control={
                                                          <Radio
                                                            checked={
                                                              paymentId === 2
                                                                ? true
                                                                : false
                                                            }
                                                          />
                                                        }
                                                        label="Request Partial Amount"
                                                        onClick={() =>
                                                          setPaymentId(2)
                                                        }
                                                      />
                                                    </RadioGroup>
                                                  </FormControl>
                                                </Typography>
                                                <Typography />
                                              </Typography>
                                              <Typography
                                                component={'div'}
                                                sx={{
                                                  marginBottom: '20px',
                                                }}>
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={deductFlatTax}
                                                      onChange={(e) =>
                                                        setDeductFlatTax(
                                                          e.target.checked,
                                                        )
                                                      }
                                                      sx={{
                                                        '&.Mui-checked': {
                                                          color: pink[600],
                                                        },
                                                        color: pink[800],
                                                      }}
                                                    />
                                                  }
                                                  label="Deduct Flat Tax"
                                                />
                                              </Typography>
                                              {paymentId === 2 && (
                                                <TableContainer
                                                  component={'div'}
                                                  className="paymentrequest-table">
                                                  <Table aria-label="simple table">
                                                    <TableHead>
                                                      <TableRow>
                                                        <TableCell
                                                          sx={{
                                                            width: '35%',
                                                          }}>
                                                          Payout Period
                                                        </TableCell>
                                                        <TableCell
                                                          sx={{
                                                            width: '35%',
                                                          }}
                                                          align="left">
                                                          Base Wage
                                                        </TableCell>
                                                        <TableCell
                                                          sx={{
                                                            width: '30%',
                                                          }}
                                                          align="left">
                                                          Enter Amount
                                                        </TableCell>
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      {payPeriodData.map(
                                                        (
                                                          item: any,
                                                          index: number,
                                                        ) => {
                                                          return (
                                                            <TableRow
                                                              key={index}>
                                                              <TableCell
                                                                component="th"
                                                                scope="row">
                                                                {moment(
                                                                  item.startDate,
                                                                ).format(
                                                                  'MMM DD',
                                                                )}{' '}
                                                                -{' '}
                                                                {moment(
                                                                  item.endDate,
                                                                ).format('DD')}
                                                              </TableCell>
                                                              <TableCell align="left">
                                                                $
                                                                {payrollData?.baseWage
                                                                  ? parseFloat(
                                                                      payrollData?.baseWage,
                                                                    ).toFixed(2)
                                                                  : 0}{' '}
                                                                <span>+</span>
                                                              </TableCell>
                                                              <TableCell align="left">
                                                                <TextField
                                                                  id="outlined-basic"
                                                                  variant="outlined"
                                                                  type={
                                                                    'number'
                                                                  }
                                                                  onChange={(
                                                                    e: any,
                                                                  ) => {
                                                                    const fIndx =
                                                                      partialAmtarray.findIndex(
                                                                        (
                                                                          it: any,
                                                                        ) =>
                                                                          it.payPeriodId ===
                                                                          item.id,
                                                                      );
                                                                    if (
                                                                      fIndx !==
                                                                      -1
                                                                    ) {
                                                                      partialAmtarray[
                                                                        fIndx
                                                                      ].requestedBufferAmount =
                                                                        parseFloat(
                                                                          e
                                                                            ?.target
                                                                            ?.value,
                                                                        );
                                                                    } else {
                                                                      partialAmtarray.push(
                                                                        {
                                                                          payPeriodId:
                                                                            item.id,
                                                                          requestedBufferAmount:
                                                                            parseFloat(
                                                                              e
                                                                                ?.target
                                                                                ?.value,
                                                                            ),
                                                                        },
                                                                      );
                                                                    }
                                                                    setPartialAmtarray(
                                                                      [
                                                                        ...partialAmtarray,
                                                                      ],
                                                                    );
                                                                  }}
                                                                />
                                                              </TableCell>
                                                            </TableRow>
                                                          );
                                                        },
                                                      )}
                                                    </TableBody>
                                                  </Table>
                                                </TableContainer>
                                              )}
                                            </>
                                          ) : (
                                            <Typography
                                              component={'div'}
                                              sx={{
                                                fontFamily:
                                                  'Red Hat Display SemiBold',
                                                justifyContent: 'center',
                                                textAlign: 'center',
                                              }}>
                                              No Records Found
                                            </Typography>
                                          )}
                                          <Typography
                                            className="notes"
                                            component={'div'}>
                                            Note: Buffer Requests need to be
                                            submitted by the Friday before
                                            payroll. If you would like to
                                            discuss buffer that is not yet
                                            available, please reach out to{' '}
                                            payroll@smtworks.com or{' '}
                                            payroll@itechus.com.
                                          </Typography>
                                          <Typography
                                            className="model-btn"
                                            component={'div'}>
                                            <Button
                                              onClick={() => setOpen(false)}
                                              variant="text">
                                              Cancel
                                            </Button>
                                            {payPeriodData.length ? (
                                              <CustomButton
                                                variant="contained"
                                                name={'Submit Request'}
                                                loading={loading}
                                                onPress={submitRequest}
                                                extraStyle={{
                                                  textTransform: 'capitalize',
                                                }}
                                              />
                                            ) : (
                                              <Typography />
                                            )}
                                          </Typography>
                                        </Typography>
                                      </Paper>
                                    </Fade>
                                  )}
                                </Popper>
                              </ClickAwayListener>
                            )}
                          </Box>
                        </Typography>
                      )}
                    <Typography className="Header-notification">
                      <Button
                        sx={{
                          border: 'unset !important',
                          color: 'unset !important',
                        }}
                        variant="text"
                        onClick={handleClick1('bottom-end')}>
                        <img src={HeaderNotification} />
                        {notificationCount && notificationCount !== 0 ? (
                          <Typography
                            component={'div'}
                            sx={{
                              background: '#DB2426',
                              borderRadius: '4px',
                              height: '8px',
                              position: 'absolute',
                              right: '8px',
                              top: '0px',
                              width: '8px',
                            }}
                          />
                        ) : (
                          <Typography component={'div'} />
                        )}
                      </Button>
                      <Box>
                        {open1 && (
                          <ClickAwayListener
                            onClickAway={() => setOpen1(false)}>
                            <Popper
                              open={open1}
                              anchorEl={anchorEl1}
                              placement={placement1}
                              transition>
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Paper className="model-widget">
                                    <Typography
                                      sx={{
                                        maxHeight: '500px',
                                        overflowY: 'scroll',
                                        width: '486px',
                                      }}
                                      className="request-payment-alert">
                                      <Typography
                                        id="modal-modal-title"
                                        variant="h6"
                                        component="h2">
                                        Notification
                                      </Typography>
                                      {notificationList.length ? (
                                        notificationList.map(
                                          (row: any, index: number) => {
                                            return (
                                              <Typography
                                                key={index}
                                                component={'div'}
                                                onClick={() => {
                                                  clearNotification(row?.id);
                                                }}
                                                sx={{
                                                  cursor: 'pointer !important',
                                                  display: 'block !important',
                                                }}
                                                className="total-payment">
                                                <Typography
                                                  sx={{
                                                    fontSize: '15px !important',
                                                  }}>
                                                  {row.message}
                                                </Typography>
                                                {row?.typeStatus === 'denied' &&
                                                  row?.queue?.comments && (
                                                    <Typography
                                                      sx={{
                                                        color:
                                                          '#9e9e9f !important',
                                                        fontFamily:
                                                          'Red Hat Display Regular !important',
                                                        fontSize:
                                                          '14px !important',
                                                        paddingTop:
                                                          '5px !important',
                                                      }}>
                                                      {row?.queue?.comments}
                                                    </Typography>
                                                  )}
                                              </Typography>
                                            );
                                          },
                                        )
                                      ) : (
                                        <Typography
                                          component={'div'}
                                          className="total-payment"
                                        />
                                      )}
                                      <Typography
                                        className="model-btn"
                                        component={'div'}>
                                        <Button
                                          variant="text"
                                          onClick={() => setOpen1(false)}>
                                          Cancel
                                        </Button>
                                        <Button
                                          variant="contained"
                                          onClick={() => {
                                            clearNotification('');
                                          }}>
                                          Clear All
                                        </Button>
                                      </Typography>
                                    </Typography>
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>
                          </ClickAwayListener>
                        )}
                      </Box>
                    </Typography>
                    <Typography className="header-profile">
                      <Link
                        to={ConsultantRoutes.PROFILE}
                        className={'sidebarA'}>
                        <Avatar
                          sx={{
                            background: '#e7e7e7',
                            borderRadius: '8px',
                            height: 50,
                            width: 50,
                          }}
                          alt="Cindy Baker"
                          src={
                            imageId
                              ? `${process.env.REACT_APP_APIURL}${
                                  MASTER_ENDPOINT.Files
                                }/${String(imageId)}`
                              : profileimg
                          }
                        />
                      </Link>
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Typography>
        </Typography>
      </Grid>
      <Typography paragraph>{props.children}</Typography>
    </Grid>
  );
};

export default ConsultantLayout;
