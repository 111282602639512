import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Modal, Typography } from '@mui/material';
// import { tableCols } from 'pages/private/PayrollSummary/PayrollTable';
import React, { useEffect, useState } from 'react';

export interface HideColumnsProps {
  setHiddenColumns: React.Dispatch<{ label: string; value: string }[]>;
}

const tableCols = [
  { field: 'summary_isVerified', headerName: 'Verified' },
  { field: 'user_workAuth', headerName: 'Work Auth' },
  { field: 'user_name', headerName: 'Name' },
  { field: 'user_jobWcCode', headerName: 'Pay Code' },
  { field: 'user_consultantStatus', headerName: 'Employment Status' },
  { field: 'summary_notes', headerName: 'Notes' },
  { field: 'summary_baseWage', headerName: 'Base Wage' },
  { field: 'summary_basePay', headerName: 'Base' },
  { field: 'user_companyCode', headerName: 'Co Code' },
  { field: 'summary_batchId', headerName: 'Batch ID' },
  { field: 'user_empCode', headerName: 'File #' },
  { field: 'summary_regHours', headerName: 'Reg Hours' },
  { field: 'summary_grossEarnings', headerName: 'Gross Earnings' },
  { field: 'summary_regEarnings', headerName: 'Reg Earnings' },
  {
    field: 'summary_currentEarnings3Code1',
    headerName: 'Current Earnings (PBN)',
  },
  {
    field: 'summary_currentEarnings3Amount1',
    headerName: 'Earnings Amount (PBN)',
  },
  { field: 'summary_currentHours3Code1', headerName: 'Current Hours (HOL)' },
  { field: 'summary_currentHours3Amount1', headerName: 'Hours Amount (HOL)' },
  {
    field: 'summary_currentEarnings3Code2',
    headerName: 'Current Earnings (HOL)',
  },
  {
    field: 'summary_currentEarnings3Amount2',
    headerName: 'Earnings Amount (HOL)',
  },
  { field: 'summary_currentHours3Code2', headerName: 'Current Hours (SIC)' },
  { field: 'summary_currentHours3Amount2', headerName: 'Hours Amount (SIC)' },
  {
    field: 'summary_currentEarnings3Code3',
    headerName: 'Current Earnings (SIC)',
  },
  {
    field: 'summary_currentEarnings3Amount3',
    headerName: 'Earnings Amount (SIC)',
  },
  {
    field: 'summary_currentEarnings3Code4',
    headerName: 'Current Earnings (COM)',
  },
  {
    field: 'summary_currentEarnings3Amount4',
    headerName: 'Earnings Amount (COM)',
  },
  {
    field: 'summary_currentEarnings3Code5',
    headerName: 'Current Earnings (COM)',
  },
  {
    field: 'summary_currentEarnings3Amount5',
    headerName: 'Earnings Amount (COM)',
  },
  {
    field: 'summary_currentEarnings3Code6',
    headerName: 'Current Earnings (OTH)',
  },
  {
    field: 'summary_currentEarnings3Amount6',
    headerName: 'Earnings Amount (OTH)',
  },
  { field: 'summary_currentHours3Code3', headerName: 'Current Hours (PTO)' },
  { field: 'summary_currentHours3Amount3', headerName: 'Hours Amount (PTO)' },
  {
    field: 'summary_currentEarnings3Code7',
    headerName: 'Current Earnings (PTO)',
  },
  {
    field: 'summary_currentEarnings3Amount7',
    headerName: 'Earnings Amount (PTO)',
  },
  {
    field: 'summary_currentEarnings3Code8',
    headerName: 'Current Earnings (RET)',
  },
  {
    field: 'summary_currentEarnings3Amount8',
    headerName: 'Earnings Amount (RET)',
  },
  {
    field: 'summary_currentEarnings3Code9',
    headerName: 'Current Earnings (RBN)',
  },
  {
    field: 'summary_currentEarnings3Amount9',
    headerName: 'Earnings Amount (RBN)',
  },
  { field: 'summary_currentAdjustDedCode1', headerName: 'Adjust DED (PDM)' },
  {
    field: 'summary_currentAdjustDedAmount1',
    headerName: 'Adjust DED Amount (PDM)',
  },
  { field: 'summary_currentAdjustDedCode2', headerName: 'Adjust DED (EXP)' },
  {
    field: 'summary_currentAdjustDedAmount2',
    headerName: 'Adjust DED Amount (EXP)',
  },
  { field: 'summary_currentAdjustDedCode3', headerName: 'Adjust DED (TXO)' },
  {
    field: 'summary_currentAdjustDedAmount3',
    headerName: 'Adjust DED Amount (TXO)',
  },
  { field: 'summary_currentAdjustDedCode4', headerName: 'Adjust DED (MED)' },
  {
    field: 'summary_currentAdjustDedAmount4',
    headerName: 'Adjust DED Amount (MED)',
  },
  { field: 'summary_currentAdjustDedCode5', headerName: 'Adjust DED (DEN)' },
  {
    field: 'summary_currentAdjustDedAmount5',
    headerName: 'Adjust DED Amount (DEN)',
  },
  { field: 'summary_currentAdjustDedCode6', headerName: 'Adjust DED (VIS)' },
  {
    field: 'summary_currentAdjustDedAmount6',
    headerName: 'Adjust DED Amount (VIS)',
  },
  { field: 'summary_currentAdjustDedCode7', headerName: 'Adjust DED (VLE)' },
  {
    field: 'summary_currentAdjustDedAmount7',
    headerName: 'Adjust DED Amount (VLE)',
  },
  { field: 'summary_currentAdjustDedCode8', headerName: 'Adjust DED (ADV)' },
  {
    field: 'summary_currentAdjustDedAmount8',
    headerName: 'Adjust DED Amount (ADV)',
  },
  { field: 'summary_psAdjustedAmount', headerName: 'Adjusted Hour' },
];

export default function HideColumns({ setHiddenColumns }: HideColumnsProps) {
  const [showHideColumnsModal, setShowHideColumnsModal] = useState(false);
  const [hideableColumns, setHideableColumns] = useState({
    Columns: tableCols.slice(1).map((tableCol) => {
      return {
        checked: true,
        label: tableCol.headerName,
        value: tableCol.field,
      };
    }),
  });

  useEffect(() => {
    setHiddenColumns(
      Object.keys(hideableColumns)
        .map((key) => {
          return hideableColumns[key].filter((column) => !column.checked);
        })
        .flat(),
    );
  }, [hideableColumns, setHiddenColumns]);

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setShowHideColumnsModal(true)}
        sx={{
          backgroundColor: '#BF0000',
          color: '#FFFFFF',
          fontFamily: 'Red Hat Display Bold',
          paddingLeft: '10px',
          paddingRight: '10px',
          position: 'absolute',
          right: '20px',
          textTransform: 'capitalize',
          top: '20px',
        }}>
        Hide Columns
      </Button>
      <Modal
        open={showHideColumnsModal}
        onClose={() => {
          setShowHideColumnsModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          alignItems: 'center',
          display: 'absolute',
          justifyContent: 'center',
          overflow: 'scroll',
        }}>
        <div className="modal-content">
          <Box
            className=""
            sx={{
              bgcolor: 'background.paper',
              borderRadius: '10px',
              boxShadow: 24,
              margin: 'auto',
              p: 4,
              position: 'absolute' as const,
              width: '50%',
            }}>
            <Typography
              id="modal-modal-title"
              component="h2"
              style={{
                fontFamily: 'Red Hat Display Bold',
                fontSize: '20px',
                marginBottom: '20px',
                textAlign: 'center',
              }}>
              Active Payroll Summary Columns
            </Typography>
            <Typography
              className="confirm-btn model-btn"
              component={'div'}
              style={{
                display: 'flex',
                justifyContent: 'right',
                marginBottom: '20px',
              }}>
              <Button
                variant="outlined"
                onClick={() => setShowHideColumnsModal(false)}
                style={{
                  backgroundColor: '#BF0000',
                  color: '#FFFFFF',
                  fontFamily: 'Red Hat Display Bold',
                  minWidth: '40px',
                  padding: '6px',
                  position: 'absolute',
                  right: '20px',
                  top: '20px',
                }}>
                <CloseIcon />
              </Button>
            </Typography>
            <Typography
              id="modal-modal-content"
              sx={{ display: 'flex', maxHeight: '50%' }}>
              {Object.keys(hideableColumns).map((key: any, index: number) => {
                return (
                  <div
                    key={index}
                    style={{
                      marginRight: '20px',
                      width: '100%',
                    }}>
                    <Typography
                      component="h3"
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        fontFamily: 'Red Hat Display Bold',
                        fontSize: '16px',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                      }}>
                      <span>{key}</span>
                      <div>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            setHideableColumns({
                              ...hideableColumns,
                              [key]: hideableColumns[key].map((column) => ({
                                ...column,
                                checked: true,
                              })),
                            });
                          }}
                          style={{
                            fontFamily: 'Red Hat Display',
                            marginRight: '10px',
                            textTransform: 'none',
                          }}>
                          Select All
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            setHideableColumns({
                              ...hideableColumns,
                              [key]: hideableColumns[key].map((column) => ({
                                ...column,
                                checked: false,
                              })),
                            });
                          }}
                          style={{
                            fontFamily: 'Red Hat Display',
                            textTransform: 'none',
                          }}>
                          Unselect All
                        </Button>
                      </div>
                    </Typography>
                    <div
                      style={{
                        display: 'grid',
                        gap: '10px',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        marginTop: '10px',
                        width: '100%',
                      }}>
                      {hideableColumns[key].map((item: any, index: number) => {
                        return (
                          <div
                            key={index}
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'left',
                              marginBottom: '0px',
                              marginRight: '0px',
                            }}>
                            <input
                              type="checkbox"
                              checked={item.checked}
                              onChange={(e) => {
                                setHideableColumns({
                                  ...hideableColumns,
                                  [key]: hideableColumns[key].map((column) => {
                                    if (column.value === item.value) {
                                      return {
                                        ...column,
                                        checked: e.target.checked,
                                      };
                                    }
                                    return column;
                                  }),
                                });
                              }}
                              style={{
                                flexShrink: 0,
                                height: '16px',
                                marginRight: '8px',
                                minHeight: '16px',
                                minWidth: '16px',
                                width: '16px',
                              }}
                            />
                            {item.label}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </Typography>
          </Box>
        </div>
      </Modal>
    </>
  );
}
