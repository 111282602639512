import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import EditNotesPng from 'assets/images/editNotes.png';
import moment from 'moment';
import React from 'react';
import { roundOfData } from 'utils/Config';

const YTDBilledTableComp = ({ val, openYtdModal, setValue, isAdmin }: any) => {
  const [isExpand, setIsExpand] = React.useState(false);
  return (
    <>
      <Grid
        className="paysheet-ytd-table"
        sx={
          !isExpand && {
            maxHeight: '120px',
            overflow: 'scroll',
          }
        }>
        {val?.pivot.map((item: any) => {
          const pivotDate =
            moment(item?.periodStartDate).format('MM/DD') +
            ' - ' +
            moment(item?.periodEndDate).format('MM/DD/YY');
          return (
            <Grid
              container
              columnSpacing={2}
              key={item.id}
              className="paysheet-ytd-table-plist-outer">
              <Grid item md={3}>
                <Tooltip title={pivotDate} placement="top">
                  <Typography className="paysheet-ytd-table-date">
                    {pivotDate}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item md={2}>
                <Typography
                  className="paysheet-ytd-table-amount"
                  sx={{ textAlign: 'right !important' }}>
                  {item?.billHours}
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography className="paysheet-ytd-table-amount">
                  {roundOfData(item?.payAmount)}
                </Typography>
              </Grid>
              <Grid
                item
                md={5}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                {item?.pivotNotes.length ? (
                  <>
                    <Tooltip title={item?.pivotNotes[0].note} placement="top">
                      <Typography
                        noWrap
                        sx={{
                          color: '#292929',
                          fontFamily: 'Red Hat Display SemiBold',
                          marginLeft: '20px !important',
                          maxWidth: '180px',
                        }}>
                        {item?.pivotNotes[0].note}
                      </Typography>
                    </Tooltip>
                    {/* <AddIcon
                    onClick={(e) => {
                      openYtdModal({
                        id: item?.pivotNotes[0].id,
                        notes: item?.pivotNotes[0].note,
                        visible: true,
                      });
                      setValue('note', item?.pivotNotes[0].note);
                    }}
                    sx={{
                      alignSelf: 'center',
                      color: '#005ebf',
                      fontSize: '18px',
                      marginLeft: '8px',
                    }}
                    className="pointer"
                  /> */}
                  </>
                ) : null}

                {isAdmin ? (
                  item?.pivotNotes.length > 0 ? (
                    <img
                      onClick={() => {
                        openYtdModal({
                          id: item?.pivotNotes[0].id,
                          notes: item?.pivotNotes[0].note,
                          visible: true,
                        });
                        setValue('note', item?.pivotNotes[0].note);
                      }}
                      src={EditNotesPng}
                      alt="Asset Not Found"
                      style={{ height: 15, marginLeft: 8, width: 15 }}
                      className="paysheetIcon"
                    />
                  ) : (
                    <AddIcon
                      className="pointer"
                      onClick={() => {
                        openYtdModal({
                          id: item?.id,
                          notes: '',
                          visible: true,
                        });
                      }}
                      sx={{
                        color: '#005ebf',
                        marginLeft: '20px',
                      }}
                    />
                  )
                ) : null}
              </Grid>
            </Grid>
          );
        })}
      </Grid>

      <Typography
        component={'div'}
        className="paysheet-table-expand-lable"
        onClick={() => {
          setIsExpand(!isExpand);
        }}>
        {isExpand ? 'See Less' : 'See All (+' + val?.pivot.length + ')'}
      </Typography>
    </>
  );
};

export default YTDBilledTableComp;
